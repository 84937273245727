import styled from 'styled-components';
import { breakpoint } from 'theme';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography/typography';

export const StyledWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.gap.sm};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto 36px auto;

  ${breakpoint.md} {
    margin: ${({ theme }) => `${theme.gap.xl} auto`};
    padding: 0;
    max-width: ${({ theme }) => theme.wrapper};
  }
`;

export const StyledListWrapper = styled.div`
  ${breakpoint.md} {
    width: 48%;
  }
`;

export const StyledList = styled.div`
  margin-top: ${({ theme }) => theme.gap.sm};
  display: flex;
  flex-wrap: wrap;

  gap: ${({ theme }) => theme.gap.xs};
  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledItem = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray5};
  padding: 12px 20px;
  border-radius: 20px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 14px;
`;

export const StyledTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
`;
