import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image/head-title-description-image';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import TitleList, { TitleListChildType } from 'components/organisms/title-list';
import BannerCard from 'components/molecules/banner-card';
import BannerCardTitles from 'components/molecules/banner-card-titles';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: { letsTalkBanner: LetsTalkBannerType };
  };
  offersITContractingPage: {
    template: {
      offersITContractingData: {
        bannerQuaternary: BannerPrimaryType;
        recruitmentTeam: ManagerSectionType;
        itContractingRoles: TitleListChildType;
        itContractingTechnologies: TitleListChildType;
        itContractingDescription: TextBannerCardType;
        advantagesSection: TextBannerCardType;
      };
    };
  };
};

export const OffersItContractingPage = ({
  pageContext,
  data: {
    offersITContractingPage: {
      template: {
        offersITContractingData: {
          bannerQuaternary: { heading, image, text },
          recruitmentTeam,
          itContractingRoles,
          itContractingTechnologies,
          itContractingDescription,
          advantagesSection,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={heading}
      subtitleTop={text}
      showDots
      positionTitle="left"
      image={image}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection variantHeading="title9" {...itContractingDescription} textColumn>
      {itContractingDescription
        ? itContractingDescription.cardList.map((item, i) => (
            <BannerCard
              {...item}
              numberCard={`${i + 1}`}
              key={item.text}
              borderColor={theme.colors.gray10}
            />
          ))
        : null}
    </TextBannerCardSection>
    <TextBannerCardSection
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      variantHeading="title9"
      {...advantagesSection}
      bgColor="#ECEFF4"
      tiles
    >
      {itContractingDescription
        ? advantagesSection.cardList.map((item) => <BannerCardTitles {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
    <TitleList items={[itContractingRoles, itContractingTechnologies]} />
    <ManagerSection {...recruitmentTeam} margin={`${theme.gap.xl} 0`} />
    <LetsTalkBanner {...letsTalkBanner} margin={`0 auto ${theme.gap.xl}`} />
  </Layout>
);

export const query = graphql`
  query OffersITContractingPage($id: String, $commonComponentsSlug: String) {
    offersITContractingPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersITContracting {
          offersITContractingData {
            advantagesSection {
              text
              subTitle
              heading
              cardList: advantagesList {
                text
                heading
                icon {
                  name
                }
              }
            }
            itContractingRoles {
              heading
              list: rolesList {
                text
              }
            }
            itContractingDescription {
              heading
              text
              extraTitle: processHeading
              extraText: processText
              cardList: processSteps {
                heading: title
                text: description
              }
            }
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            itContractingTechnologies {
              heading
              list: technologiesList {
                text
              }
            }
            recruitmentTeam {
              backgroundImage {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              businessCards {
                businessCard {
                  contactList {
                    type
                    link {
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                      ariaLabel
                    }
                  }
                  fullName
                  image {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  text
                  position
                  positionTitle
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersItContractingPage;
