import React from 'react';

import Typography from 'components/atoms/typography';

import {
  StyledIcon,
  StyledItem,
  StyledList,
  StyledListWrapper,
  StyledTitle,
  StyledWrapper,
} from './title-list.styles';

export type TitleListChildType = {
  heading: string;
  list: {
    text: string;
  }[];
};
export type TitleListType = {
  items: TitleListChildType[];
};

const TitleList = ({ items }: TitleListType) => (
  <StyledWrapper>
    {items.map(({ heading, list }) => (
      <StyledListWrapper key={heading}>
        <StyledTitle variant="title2" color="gray100" component="h2" html={heading} />
        {list ? (
          <StyledList>
            {list.map(({ text }) => (
              <StyledItem key={text}>
                <StyledIcon name="approve" />
                <Typography variant="title4" color="gray100" html={text} />
              </StyledItem>
            ))}
          </StyledList>
        ) : null}
      </StyledListWrapper>
    ))}
  </StyledWrapper>
);

export default TitleList;
